.portfolio {

    width: 90%;
    display: inline-flex;
    margin-top: 4vh;
    margin-bottom: 4vh;
    align-items: center;
    overflow-y: scroll;
    transition: opacity;
    flex: 2;
    background: linear-gradient(117.71deg, rgba(255, 255, 255, 0.297) 6.61%, rgba(255, 255, 255, 0.045) 74.56%);
    mix-blend-mode: normal;
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(40px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 40px;

    animation: fadein 1s;

    display: flex;
    justify-content: flex-start;
    list-style: none;
    flex-direction: column;

  }

  ::-webkit-scrollbar {
    width: 0.8rem;
}
 
::-webkit-scrollbar-track {
    border-radius: 10px;
    margin-top: 3vh;
    margin-bottom: 3vh;
}
 
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);  */
    background-color: #0e161d4d;

}

.company {
    padding: 2vh 6vh 2vh 6vh;
    margin-bottom: 1vh;
    border-radius: 10px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 3rem;
    line-height: 2rem;
    text-align: center;
    text-shadow: 0 2px 3px hsla(0deg, 0%, 0%, 0.25);

    color: white;
    background-image: linear-gradient(40deg, #27747C, #AECFDF);
    box-shadow: 0 0 15px hsla(0deg, 0%, 0%, 0.5);

    display: flex;
    position: sticky;
    top: 1vh;
    z-index: 2;
}



.date {

  font-family: 'Montserrat';
  /* font-style: italic; */
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;

  color: rgb(4, 12, 24);
  text-shadow: 0 2px 3px hsla(0deg, 0%, 0%, 0.25);
}

.description {
    font-family: 'Montserrat';
    font-style: normal;
    margin: 1rem 5rem 2rem 5rem;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 0.04em;
    color: rgb(0, 0, 0);
}

.portfolio-header {
    padding-top: 3vh;
    padding-bottom: 1vh;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 3rem;
    line-height: 2rem;
    text-align: center;

    color: rgb(0, 0, 0);
}

.portfolio-items {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.jobphoto > img {
  filter: drop-shadow(0 0 0.75rem rgb(61, 16, 16));
  border-radius: 50%;
  margin-top: 2vh;
  margin-bottom: 2vh;
  max-height: 35vh;
}

.guelph-logo {
  max-width: 75vh;
  margin-top: 2vh;
}

.system-model {
  margin-top: 2vh;
  max-width: 100vh;
}

.alignment {
  max-width: 80vh;
  border-radius: 20px;
  margin-top: 2vh;
}

@keyframes fadein {
  0% {
    opacity: 0%
  }
  100% {
    opacity: 100%
  }
}