.app {
    height: 100vh;
    background-image: url("/public/toronto.jpg");
    background-size: cover;
    background-repeat: no-repeat;

    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;
}