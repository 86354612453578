.mainpage {
    display: grid;
    grid-template-columns: 50vw 50vw;
    grid-template-rows: 25vh 40vh;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    margin-right: 5vh;
    animation: fadein 1s;
}
        
.hello { 
    grid-area: 1 / 2 / 2 / 3;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
}

.hello > h1 {
    font-family: montserrat;
    font-weight: 700;
    font-size: 4rem;
    color: white;
    text-align: center;
}

p {
    font-style: normal;
    font-family: 'Kumbh sans';
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 25px;
    color: rgb(0, 0, 0);
    text-align: center;
}

@keyframes fadein {
    0% {opacity: 0%};
    100% {opacity: 100%};
}

@media only screen and (max-width: 1000px) {
    .mainpage {
        display: flex;
        margin-right: 0px;
    }
    .hello {
        margin-top: 5vh;
    }
}