/* Pulled from a public Codepen https://codepen.io/lis186/pen/azNOOp */

.container {
    padding-top: 5vh;
    max-width: 80%;
    margin: 0 auto;
  }
  
  .photos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: stretch;
    padding: 0;
  }
  
  .photos img {
    vertical-align: bottom;
    animation: fade-in 3s;
    filter: drop-shadow(16px 16px 20px black);
    padding: 2vh;
  }
  
  @media screen and (min-width: 1024px) {
    .photos img {
      width: calc(100%/5);
      height: calc(100%/5);
    }
  }
  
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .photos img {
      width: calc(100%/3);
      height: calc(100%/3);
    }
  }
  
  @media screen and (min-width: 481px) and (max-width: 768px) {
    .photos img {
      width: calc(100%/2);
      height: calc(100%/2);
    }
  }
  
  @media screen and (min-width: 321px) and (max-width: 480px) {
    .photos img {
      width: calc(100%/1);
      height: calc(100%/1);
    }
  }
  
  @media screen  and (max-width: 320px) {
    .photos img {
      width: 100%;
      height: 100%;
    }
  }
