.navigator{
    width: 90%;
    display: inline-flex;
    margin-top: 5vh;
    height: 10vh;
    background-color: #30383E;
    align-items: center;
}

ul {
    display: flex;
    justify-content: space-evenly;
    list-style: none;
    flex-direction: row;
}

li {
    padding-left: .2vh;
    padding-right: .2vh;
}

a, button {
    all: unset;
    cursor: pointer;
    font-style: normal;
    font-family: 'Kumbh sans';
    font-weight: 400;
    font-size: 1rem;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.15em;
    color: white;
    text-decoration: none;
    
}

.selected {
    color: black;
}

button:hover, .selected {
    color: #27747C;
}

.left {
    width: 50%;
}

.middle {
    width: 25%;
}

.right { /* Icons */
    width: 25%;
    display: inline-flex;
    justify-content: space-evenly;
    padding-right: 10px;
    font-size: xx-large;
}

@media only screen and (max-width: 1000px) {
    ul {
        flex-direction: column;
    }
    li {
        padding-left: 0vh;
        padding-right: 0vh;
    }
    .navigator {
        height: 8rem;
    }
    .middle {
        width: 15%;
    }
    .right {
        width: 35%;
    }
}

.fa-icon {
    color: white;
    transition: color 0.25s ease-out;
    transition: transform 0.25s ease-out;
}

.fa-icon:hover {
    cursor: pointer;
    color: #27747C;
    transform: scale(1.5);
}